import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BusinessLogicError } from '../../services/constants';
import { ArrayUtils } from './../../utils/array.utils';
import {
  BANK_ACCOUNT_TYPES,
  IncorrectValidationResultsDetail,
  IsPaymentProcessStopped,
  ListFilter,
  NOTIFICATION_TYPES,
  NeedsConsumptionTax,
  NeedsWithholdingTax,
  PAYMENT_METHODS,
  PAYMENT_THRESHOLD_SETTINGS,
  PERSONALITY_FLGS,
  PartialList,
  RESIDENT_TYPES,
  Response,
  StdMst,
} from './../models';

export interface Payee extends StdMst {
  name?: string;
  kana?: string;
  post_code?: string;
  address1?: string;
  address2?: string;
  payment_record_address?: string;
  addressee1?: string;
  addressee2?: string;
  addressee_honorific?: string;
  tel?: string;
  fax?: string;
  email?: string;
  emergency_contact_name?: string;
  emergency_contact_tel?: string;
  invoice_registration_number?: string;
  invoice_registration_number_effective_date?: Date;
  payment_method?: string;
  bank_code?: string;
  bank_name?: string;
  bank_branch_code?: string;
  bank_branch_name?: string;
  bank_account_type?: number;
  bank_account_number?: string;
  bank_account_name?: string;
  bank_account_memo?: string;
  ebook_royalty_notification_type?: number;
  payment_notification_type?: number;
  payment_record_notification_type?: number;
  resident_type?: string;
  personality_flg?: number;
  needs_withholding_tax?: boolean;
  needs_consumption_tax?: boolean;
  optional_withholding_tax_rate?: number;
  is_payment_process_stopped?: boolean;
  payment_process_stopped_reason?: string;
  payment_threshold_setting?: string;

  payment_method_text?: string;
  bank_account_type_text?: string;
  ebook_royalty_notification_type_text?: string;
  payment_notification_type_text?: string;
  payment_record_notification_type_text?: string;
  resident_type_text?: string;
  needs_withholding_tax_text?: string;
  needs_consumption_tax_text?: string;
  personality_flg_text?: string;
  is_payment_process_stopped_text?: string;
  payment_threshold_setting_text?: string;
  //光和　長谷川　MILK-933 24/12/19　追加
  memo?: string;
}

export interface PayeeFilter extends ListFilter {
  code?: string;
  name?: string;
  is_payment_process_stopped?: string;
  payment_threshold_setting?: string;
}

@Injectable()
export class PayeesService {
  constructor(private http: HttpClient) {}

  private formatResponse(resp: Payee[]) {
    resp.forEach((e) => {
      e.invoice_registration_number_effective_date &&
        (e.invoice_registration_number_effective_date = new Date(
          e.invoice_registration_number_effective_date
        ));
      e.payment_method_text = PAYMENT_METHODS[e.payment_method];
      e.bank_account_type_text = BANK_ACCOUNT_TYPES[e.bank_account_type];
      e.ebook_royalty_notification_type_text =
        NOTIFICATION_TYPES[e.ebook_royalty_notification_type];
      e.payment_notification_type_text =
        NOTIFICATION_TYPES[e.payment_notification_type];
      e.payment_record_notification_type_text =
        NOTIFICATION_TYPES[e.payment_record_notification_type];
      e.resident_type_text = RESIDENT_TYPES[e.resident_type];
      e.needs_withholding_tax_text = e.needs_withholding_tax
        ? NeedsWithholdingTax.NEED.text
        : NeedsWithholdingTax.NOT_NEED.text;
      e.needs_consumption_tax_text = e.needs_consumption_tax
        ? NeedsConsumptionTax.NEED.text
        : NeedsConsumptionTax.NOT_NEED.text;
      e.personality_flg_text = PERSONALITY_FLGS[e.personality_flg];
      e.is_payment_process_stopped_text = e.is_payment_process_stopped
        ? IsPaymentProcessStopped.PROCESS_STOPPED.text
        : IsPaymentProcessStopped.PROCESS_UNSTOPPED.text;
      e.payment_threshold_setting_text =
        PAYMENT_THRESHOLD_SETTINGS[e.payment_threshold_setting];
    });
  }

  emptyType(): Payee {
    return {
      code: null,
      name: null,
      needs_withholding_tax: null,
      needs_consumption_tax: null,
    };
  }

  listByFilter(d: PayeeFilter): Observable<Response<PartialList<Payee>>> {
    let params = new HttpParams()
      .append('limit', String(d.limit))
      .append('offset', String(d.offset));
    d.code && (params = params.append('code', d.code));
    d.name && (params = params.append('name', d.name));
    d.is_payment_process_stopped &&
      (params = params.append(
        'is_payment_process_stopped',
        (
          d.is_payment_process_stopped ==
          IsPaymentProcessStopped.PROCESS_STOPPED.id
        ).toString()
      ));
    d.payment_threshold_setting &&
      (params = params.append(
        'payment_threshold_setting',
        d.payment_threshold_setting
      ));
    return this.http
      .get<Response<PartialList<Payee>>>(`/authorized/payees`, { params })
      .pipe(tap((res) => this.formatResponse(res.data.list)));
  }

  listByCodes(d: string[]): Observable<Response<Payee[]>> {
    if (d.length == 0) {
      let resp = { success: true, data: [] };
      return of(resp);
    } else {
      return forkJoin(
        ArrayUtils.toChunks(d).map((codes) =>
          this.http.post<Response<Payee[]>>(`/authorized/payees/codes`, codes)
        )
      ).pipe(
        map((respArr) => {
          if (respArr.some((e) => !e.success)) {
            return { success: false, data: null };
          }

          const merged = [];
          respArr.forEach((e) => {
            this.formatResponse(e.data);
            merged.push(...e.data);
          });
          return { success: true, data: merged };
        })
      );
    }
  }

  listByCodeOrNameLike(c: string, n: string): Observable<Response<Payee[]>> {
    let params = new HttpParams().append('fuzzy', '1');
    c && (params = params.append('code', c));
    n && (params = params.append('name', n));
    return this.http
      .get<Response<Payee[]>>(`/authorized/payees`, { params })
      .pipe(tap((res) => this.formatResponse(res.data)));
  }

  post(d: Payee): Observable<Response<any>> {
    let entries: Payee[] = new Array();
    entries.push(d);
    return this.http.post<Response<any>>(`/authorized/payees`, entries);
  }

  put(d: Payee): Observable<Response<any>> {
    let entries: Payee[] = new Array();
    entries.push(d);
    return this.http.put<Response<any>>(`/authorized/payees`, entries);
  }

  postBulk(
    d: Payee[]
  ): Observable<Response<IncorrectValidationResultsDetail[]>> {
    let details: IncorrectValidationResultsDetail[] = new Array();
    return forkJoin(
      ArrayUtils.toChunks(d).map((payees) =>
        this.http.post<Response<any>>(`/authorized/payees`, payees)
      )
    ).pipe(
      map((respArr) => {
        if (respArr.some((e) => !e.success)) {
          respArr.forEach((resp) => {
            switch (resp.data.error as BusinessLogicError) {
              case BusinessLogicError.IncorrectValidationResults:
                details = Object.assign(details, resp.data.detail);
                break;
            }
          });
          return { success: false, data: details };
        }
        return { success: true, data: null };
      })
    );
  }

  delete(d: Payee): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`/authorized/payees/${d.code}`);
  }
}
